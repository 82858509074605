import '@mdi/font/css/materialdesignicons.css'

import {createVuetify} from 'vuetify'

export default defineNuxtPlugin((app) => {
    const vuetify = createVuetify({
        ssr: true,
        locale: {
            messages: {
                loading: 'Carregando conteúdo...',
            },
        },
        theme: {
            defaultTheme: 'light',
            variations: {
                colors: ['primary', 'secondary'],
                lighten: 3,
                darken: 3,
            },
            themes: {
                light: {
                    dark: false,
                    colors: {
                        primary: '#0033a1',
                        secondary: '#FF4C00',
                        warning: '#FFAB1A',
                        success: '#58C322',
                        danger: '#D41111',
                    },
                },
            },
        },
    })
    app.vueApp.use(vuetify)
})
