<template>
  <Title>Sempre Internet</Title>
  <Meta content="Sempre Internet" name="author"></Meta>
  <Meta content="Sempre Internet 2024" name="generator"></Meta>
  <NuxtLayout
      name="404">
    <LazyAppBarSempre/>
    <v-layout
        class="flex-column">
      <v-container
          fluid>
        <v-container
            class="text-center"
            style="height: 86vh; display: flex; flex-direction: column; justify-content: center; align-items: center;">
          <p class="titulo">
            Ops... página não encontrada!
          </p>
          <p class="mt-5 texto">
            Clique no botão abaixo para voltar à página inicial.
          </p>
          <v-btn
              class="font-weight-bold mt-10 button"
              color="primary"
              elevation="10"
              prepend-icon="mdi-arrow-left"
              rounded="lg"
              size="x-large"
              variant="elevated"
              @click="goBack">
            Voltar
          </v-btn>
        </v-container>
      </v-container>
      <LazyFooterSempre/>
    </v-layout>
  </NuxtLayout>
</template>
<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  methods: {
    goBack() {
      this.$router.push('/')
    }
  }
});
</script>
<style scoped>
.texto {
  font-size: 24px;
}

.titulo {
  font-size: 54px;
}
</style>
