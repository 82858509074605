import {ofetch} from "ofetch";
import {useAlertStore} from "~/stores/alert";

export default defineNuxtPlugin((_nuxtApp) => {
    const pinia = usePinia();
    const alertStore = useAlertStore(pinia);

    globalThis.$fetch = ofetch.create({
        onResponseError({response}) {
            if (response.status >= 500) {
                alertStore.showError('Erro interno do servidor');
            }
            if (response.status >= 400 && response.status <= 499) {
                if (response._data.message) {
                    alertStore.showError(response._data.message);
                } else {
                    alertStore.showError('Parâmetros inválidos');
                }
            }
        },
        onRequestError({error, response}) {
            if (!response?.status) {
                alertStore.showError('Servidor indisponível');
            }
        }
    })
})
