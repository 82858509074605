<template>
  <Head>
    <Title>Sempre Internet</Title>
    <Meta content="Sempre Internet" name="author"></Meta>
    <Meta content="Sempre Internet 2024" name="generator"></Meta>
  </Head>
  <NuxtLayout>
    <v-app>
      <LazyAceitarCookies/>
      <Alert/>
      <NuxtPage/>
    </v-app>
  </NuxtLayout>
</template>
<style lang="scss">
@import "@mdi/font/css/materialdesignicons.css";
@import "vuetify/styles";
@import "assets/css/main.css";
</style>
<script lang="ts" setup>
</script>
